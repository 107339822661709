import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { RiRobot2Line } from "react-icons/ri";
import { AiOutlineRight, AiOutlineLeft, AiOutlineProduct } from "react-icons/ai";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { GrChat } from "react-icons/gr";
import { FaCircle } from "react-icons/fa";
import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyD4SzTGrIfag6uxqvjwuNtQB3s2oIBJfOg",
  authDomain: "af3bots.firebaseapp.com",
  projectId: "af3bots",
  storageBucket: "af3bots.appspot.com",
  messagingSenderId: "987151630655",
  appId: "1:987151630655:web:5d167e611b0956ae4b138a",
  measurementId: "G-09FN9ZX2L3"
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Painel = () => {
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [systemMessage, setSystemMessage] = useState('');
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const clientesSnapshot = await getDocs(collection(firestore, 'clientes'));
        if (!clientesSnapshot.empty) {
          const clientesData = clientesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setClientes(clientesData);
        }
      } catch (error) {
        console.error("Erro ao obter clientes:", error);
      }
    };
    fetchClientes();
  }, []);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      window.location.href = 'https://chatbot.af3.com.br'; // Redirecionar após logout
    } catch (error) {
      console.error('Erro ao fazer logout:', error.message);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleConnection = async () => {
    try {
      const response = await fetch('https://us-central1-af3bots.cloudfunctions.net/api/whatsapp');
      const message = await response.text();
      console.log(message);

      setIsConnected(!isConnected);
      setSystemMessage(message);
      clearMessage();
    } catch (error) {
      console.error('Erro ao conectar/desconectar:', error);
      setSystemMessage('Erro ao conectar/desconectar.');
      clearMessage();
    }
  };

  const handleAddClick = () => {
    setSystemMessage('Informação adicionada com sucesso!');
    clearMessage();
  };

  const clearMessage = () => {
    setTimeout(() => {
      setSystemMessage('');
    }, 4000); // Oculta a mensagem após 4 segundos
  };

  if (!user) {
    return <Navigate to="/Chatbot" />; // Redirecionar para a página de login se não houver usuário autenticado
  }

  return (
    <div className="painel">
      {systemMessage && (
        <div className="system-message">
          <p>{systemMessage}</p>
        </div>
      )}

      <aside className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
        {!isSidebarOpen && (
          <div className="circle-container">
            <FaCircle size={28} color={isConnected ? '#4CAF50' : '#FE0000'} />
            <p>{isConnected ? 'On' : 'Off'}</p>
          </div>
        )}
        <RiRobot2Line className="RiRobot2Line" size="80" color={isConnected ? '#4CAF50' : '#FE0000'} />
        <ul>
          <li>
            <button onClick={toggleConnection} className="toggle-connection-button">
              {isConnected ? 'Desligar' : 'Ligar'}
            </button>
          </li>
          <li>
            <div className="qr-code">
              <p>[QR Code]</p>
            </div>
          </li>
          <li>
            <div className="status">
              <span className={`status-icon ${isConnected ? 'connected' : 'disconnected'}`}></span>
              <span>{isConnected ? 'Conectado' : 'Desconectado'}</span>
            </div>
          </li>
          <li><a href="#">Plano</a></li>
          <li><a href="#">Whatsbot</a></li>
          <li><a href="#">Chatbot</a></li>
          <li><a href="#">Minha conta</a></li>
          <li><a href="#">Pagamentos</a></li>
          <li><a href='#'>Versão 1.0.05 </a></li>
        </ul>

        <img src="Logo1.png" width="150px" alt="AF3 Soluções" style={{ borderRadius: '5px' }} />
      </aside>

      <button className={`toggle-button ${isSidebarOpen ? 'expanded' : ''}`} onClick={toggleSidebar}>
        {isSidebarOpen ? <AiOutlineLeft size="20" /> : <AiOutlineRight size="20" />}
      </button>

      <main className={`content ${isSidebarOpen ? 'shifted' : ''}`}>
        <div className="grid">
          <div className="card">
            <h3>Instruções</h3>
            <MdOutlineIntegrationInstructions size="30" />
          </div>
          <div className="card">
            <h3>Produtos</h3>
            <AiOutlineProduct size="30" />
          </div>
          <div className="card">
            <h3>Chatlog</h3>
            <GrChat size="30" />
          </div>
          <div className="fotosair">
            {user.photoURL && (
              <div className="user-photo">
                <img src={user.photoURL} alt="Foto do usuário" />
              </div>
            )}
          </div>
          <span className="" onClick={handleLogout}>Sair</span>
        </div>

        <div className="textarea-container">
          <button className="button adicionar" onClick={handleAddClick}>ADICIONAR</button>
          <button className="button editar" onClick={handleAddClick}>EDITAR </button>
          <button className="button salvar" onClick={handleAddClick}>SALVAR </button>
          <textarea className="chat-textarea" placeholder={`Olá, ${user.displayName}. Digite sua mensagem aqui...`}></textarea>
        </div>

        <img src="Logo1.png" width="300px" alt="AF3 Soluções" style={{ borderRadius: '5px' }} />
        <img src="Logohpreto.png" width="300px" alt="AF3 Soluções" style={{ borderRadius: '5px' }} />
        <img src="Logo2.png" width="300px" alt="AF3 Soluções" style={{ borderRadius: '5px' }} />
      </main>
    </div>
  );
};

export default Painel;