import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'; // Importa o estilo CSS
import Chatbot from './Chatbot';
import Home from './Home';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/Chatbot" element={<Chatbot />} />
            <Route path="/*" element={<Home />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}
export default App;
