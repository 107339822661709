import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { RiRobot2Line } from "react-icons/ri";
import { AiOutlineRight, AiOutlineLeft, AiOutlineProduct } from "react-icons/ai";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { GrChat } from "react-icons/gr";

const firebaseConfig = {
  apiKey: "AIzaSyD4SzTGrIfag6uxqvjwuNtQB3s2oIBJfOg",
  authDomain: "af3bots.firebaseapp.com",
  projectId: "af3bots",
  storageBucket: "af3bots.appspot.com",
  messagingSenderId: "987151630655",
  appId: "1:987151630655:web:5d167e611b0956ae4b138a",
  measurementId: "G-09FN9ZX2L3"
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Home = () => {
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [systemMessage, setSystemMessage] = useState('');
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const clientesSnapshot = await getDocs(collection(firestore, 'clientes'));
        if (!clientesSnapshot.empty) {
          const clientesData = clientesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setClientes(clientesData);
        }
      } catch (error) {
        console.error("Erro ao obter clientes:", error);
      }
    };
    fetchClientes();
  }, []);


  const clearMessage = () => {
    setTimeout(() => {
      setSystemMessage('');
    }, 3000); // Oculta a mensagem após 3 segundos
  };

  if (!user) {
    return <Navigate to="/chatbot" />;
  }

};

export default Home;
