import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import Painel from './Painel';
import './Login.css';
import Header from './Header2';

const firebaseConfig = {
};

// Inicialize o Firebase se ainda não estiver inicializado
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Chatbot = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [systemMessage, setSystemMessage] = useState('');


  const handleGoogleLogin = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);

      const userEmail = result.user.email;
      const authorizedEmails = ['umdevbr@gmail.com', 'fndo.xyz@gmail.com','fcbertoo@gmail.com' ];

      if (authorizedEmails.includes(userEmail)) {
        setLoggedIn(true);
        setSystemMessage('(a)');
        clearMessage();
      } else {
        await firebase.auth().signOut();
        setSystemMessage('Desculpe, mas você não tem autorização para acessar esta área.');
        clearMessage();
      }
    } catch (error) {
      console.error('Erro ao fazer login com o Google:', error.message);
      setSystemMessage(`Falha no login com o Google, tente novamente.`);
      clearMessage();
    }
  };

  const clearMessage = () => {
    setTimeout(() => {
      setSystemMessage('');
    }, 4000); //
  };

  if (loggedIn) {
    return <Painel />;
  }

return (
  <div className="login-container">

    <Header onLoginClick={handleGoogleLogin} />
            <img src="background.jpeg" alt="background do site AF3 Soluções" className="background" />

    <h2>Dê um up no seu atendimento com um chatbot!</h2>

 <a href="https://api.whatsapp.com/send?phone=5511984782523" target="_blank" rel="noopener noreferrer" className="whatsapp-link">
  <img src="whatsapp.png" alt="Ícone do WhatsApp" />
</a>
    {systemMessage && (
      <div className="system-messageoff">
        <p>{systemMessage}</p>
      </div>
    )}
  </div>
);
};

export default Chatbot;
