import React from 'react';
import './Header2.css';
import { FiLogIn } from "react-icons/fi";

function Header2({ onLoginClick }) {
  return (
    <div className="container">
      <header className="header">
        <div className="logo">
          <a href="https://chatbot.af3.com.br/" target="_self">
            <img src="Logo1.png" alt="Logo AF3 Soluções" />
          </a>
        </div>
        <nav className="menu">
          <ul>
            {/*<li><a href="#home">Home</a></li>
            <li><a href="#services">Serviços</a></li>
            <li><a href="#contact">Contato</a></li>*/}
          </ul>
        </nav>
        <div className="login-button">
          <p onClick={onLoginClick}>Entrar <FiLogIn size="25" /></p>
        </div>
      </header>
    </div>
  );
}
export default Header2;
